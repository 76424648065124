header {
  position: relative;
  width: 100%;
  background: var(--white);
  box-shadow: 0 0 5px black;
  z-index: 2;
}

nav p {
  margin-bottom: 0;
  font-weight: 800;
}

.private-layout {
  min-height: 100vh;
  max-width: 1140px;
  margin: auto;
  background: var(--main-bg-color);
}

.registration {
  padding: 20px var(--spacing-sm);
}

.registration > .main-section {
  max-width: 540px;
  margin: auto;
}

.main-section {
  box-shadow: 0 0 5px black;
  border-radius: 0.25rem;
  margin-bottom: var(--spacing-xl);
}

.main-section h2 {
  background: var(--section-header-bg);
  color: var(--section-header-text);
  padding-left: var(--spacing-sm);
}

.main-section h3 {
  text-align: left;
  text-decoration: underline;
}

.main-section-content {
  padding: var(--spacing-sm);
}

@media screen and (min-width: 580px) {
  .main-section-content {
    padding: var(--spacing-md);
  }
}

@media screen and (max-width: 768px) {
  nav {
    max-height: 100vh;
  }
  nav::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .registration {
    /* padding: var(--spacing-md) 0; */
    padding-top: 60px;
  }

  .private-layout {
    padding-top: 60px;
  }

  header {
    position: fixed;
  }

  .navbar.fixed {
    position: fixed;
  }

  nav {
    overflow-y: auto;
  }
}
